<template>
  <section
    id="contactForm"
    class="overflow-hidden gradient"
  >
    <v-row
      class="info white--text"
      no-gutters
    >
      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/contact.png')"
          height="100%"
        />
      </v-col>

      <v-col
        class="pa-5"
        cols="12"
        md="6"
      >
        <base-bubble-1 />

        <base-heading
          size="text-h4 text-uppercase text-lg-left p-2 mb-4"
          title="Registro"
          weight="bold"
        />
        <p>
          <i> * Es obligatorio realizar un pre-registro para confirmar su cupo. (Cupo Limitado)</i>
        </p>

        <v-sheet
          color="transparent"
          max-width="600"
        >
          <v-form
            v-if="formStep"
            ref="form"
          >
            <v-select
              v-model="cityEvent"
              color="info"
              label="Evento"
              solo
              flat
              :items="itemsEvents"
              required
              :error-messages="cityEventErrors"
              @input="$v.cityEvent.$touch()"
              @blur="$v.cityEvent.$touch()"
            />
            <v-text-field
              v-model="firstName"
              color="info"
              label="Nombre"
              solo
              flat
              required
              :error-messages="firstNameErrors"
              @input="$v.firstName.$touch()"
              @blur="$v.firstName.$touch()"
            />
            <v-text-field
              v-model="lastName"
              color="info"
              label="Apellido"
              solo
              flat
              required
              :error-messages="lastNameErrors"
              @input="$v.lastName.$touch()"
              @blur="$v.lastName.$touch()"
            />

            <v-text-field
              v-model.trim="email"
              color="info"
              label="Email"
              solo
              flat
              required
              :error-messages="emailErrors"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            />

            <v-text-field
              v-model="institucion"
              color="info"
              label="Institución"
              solo
              flat
              required
              :error-messages="institucionErrors"
              @input="$v.institucion.$touch()"
              @blur="$v.institucion.$touch()"
            />

            <v-btn
              block
              color="primary"
              :depressed="true"
              :tile="true"
              class="font-weight-bold"
              x-large
              style="border-radius: 5px;"
              :disabled="disableButtonSend"
              :loading="computedSendingForm"
              @click="sendForm"
            >
              Enviar
            </v-btn>
            <v-btn
              block
              :color="'secondary'"
              :depressed="true"
              :tile="true"
              class="font-weight-bold mt-5"
              x-large
              style="border-radius: 5px;"
              :disabled="disableButtonClear"
              @click="clear"
            >
              Limpiar Formulario
            </v-btn>
          </v-form>

          <v-card
            v-else-if="successForm"
            color="white"
            elevation="2"
            height="400"
          >
            <v-card-title>
              <v-container>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="success--text text-center font-weight-light"
                  >
                    Registro enviado de forma satisfactoria
                    <v-divider />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-container
                class="pa-sm-10 my-sm-10"
              >
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    class="success--text text-center font-weight-light"
                    style="font-size: 3rem"
                  >
                    <v-icon
                      style="font-size: 4rem"
                      class="success--text"
                    >
                      mdi-check-circle
                    </v-icon>
                    <br>
                    Muchas gracias!!!
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

          <v-card
            v-else-if="errorForm"
            color="white"
            elevation="2"
            height="400"
          >
            <v-card-title>
              <v-container>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    class="danger--text text-center font-weight-light"
                  >
                    Ocurrió un error al enviar el formulario
                    <v-divider />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-container
                class="pa-sm-10 my-sm-10"
              >
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    class="danger--text text-center font-weight-light"
                    style="font-size: 2rem"
                  >
                    <v-icon
                      style="font-size: 4rem"
                      class="danger--text"
                    >
                      mdi-alert-circle
                    </v-icon>
                    <br>
                    Intente hacerlo de nuevo
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import { EVENT_1, EVENT_2 } from '../../constanst'
  import { db } from '../../services/firebaseDB'
  import { collection, addDoc } from 'firebase/firestore'
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'SectionContactForm',
    mixins: [validationMixin],
    validations: {
      cityEvent: { required },
      firstName: { required },
      lastName: { required },
      email: { required, email },
      institucion: { required },
    },
    data: () => ({
      itemsEvents: [EVENT_1.city, EVENT_2.city],
      registers: {},
      cityEvent: '',
      firstName: '',
      lastName: '',
      email: '',
      institucion: '',
      formStep: true,
      sendingForm: false,
      successForm: false,
      errorForm: false,
    }),
    computed: {
      cityEventErrors () {
        const errors = []
        if (!this.$v.cityEvent.$dirty) return errors
        !this.$v.cityEvent.required && errors.push('Selecciones un evento.')
        return errors
      },
      firstNameErrors () {
        const errors = []
        if (!this.$v.firstName.$dirty) return errors
        !this.$v.firstName.required && errors.push('Se debe completar este campo')
        return errors
      },
      lastNameErrors () {
        const errors = []
        if (!this.$v.lastName.$dirty) return errors
        !this.$v.lastName.required && errors.push('Se debe completar este campo')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('El texto ingresado no posee el formato de un correo electrónico')
        !this.$v.email.required && errors.push('Se debe completar este campo')
        return errors
      },
      institucionErrors () {
        const errors = []
        if (!this.$v.institucion.$dirty) return errors
        !this.$v.institucion.required && errors.push('Se debe completar este campo')
        return errors
      },
      disableButtonSend () {
        return this.$v.$invalid
      },
      disableButtonClear () {
        return this.$v.$invalid &&
          this.cityEvent === '' &&
          this.firstName === '' &&
          this.lastName === '' &&
          this.institucion === '' &&
          this.email === ''
      },
      computedSendingForm () {
        return this.sendingForm
      },
    },
    methods: {
      sendForm: async function () {
        this.$v.$touch()
        if (this.$v.$invalid) return
        try {
          this.sendingForm = true
          const docRef = await addDoc(collection(db, 'registers'), {
            cityEvent: this.cityEvent,
            firstName: this.firstName,
            lastName: this.lastName,
            institucion: this.institucion,
            email: this.email,
          })
          this.formStep = false
          this.successForm = !!docRef.id
          this.sendingForm = false
        } catch (e) {
          console.error('Error adding document: ', e)
          this.formStep = false
          this.errorForm = true
          this.sendingForm = false
        }
        this.clear()
      },
      clear: function () {
        this.$v.$reset()
        this.sendingForm = false
        this.cityEvent = ''
        this.firstName = ''
        this.lastName = ''
        this.institucion = ''
        this.email = ''
      },
    },
  }
</script>

<style scoped>
.gradient
{background: rgb(232,188,27);
background: linear-gradient(90deg, rgba(232,188,27,1) 0%, rgba(241,160,27,1) 35%);}
</style>
