export const EVENT_1 = {
    city: 'Copantl Hotel & Convention Center, San Pedro Sula, Honduras',
    date: 'Miercoles 9 de marzo del 2022',

}

export const EVENT_2 = {
    city: 'Hotel Clarion Real Tegucigalpa, Tegucigalpa, Honduras',
    date: 'Viernes 11 de marzo 2022',
}
