// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA2ztLMQo2b6nVhxjxPnnUyx9xT8Ng5GGw',
  authDomain: 'didaticsformslanding.firebaseapp.com',
  projectId: 'didaticsformslanding',
  storageBucket: 'didaticsformslanding.appspot.com',
  messagingSenderId: '282757233351',
  appId: '1:282757233351:web:c28c1ace578c2926a59ce2',
  measurementId: 'G-S1WBK0V6RE',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
